import { Breadcrumb } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

function PageIndicator(): JSX.Element {
  const location = useLocation();
  let pathParts: string[] = location.pathname.split('/').filter(e => e !== '');
  if (pathParts.length >= 3) {
    pathParts = pathParts.slice(0, 2);
  }

  const items: JSX.Element[] = [];
  let path = '';
  pathParts.forEach((part, i) => {
    path += '/' + part;
    if (part === pathParts[pathParts.length - 1]) {
      items.push(
        <Breadcrumb.Item key={i} active>
          {part.replaceAll('_', ' ')}
        </Breadcrumb.Item>
      );
    } else {
      items.push(
        <Breadcrumb.Item style={{ color: 'black' }} key={i} href={path} active>
          {part.replaceAll('_', ' ')}
        </Breadcrumb.Item>
      );
    }
  });

  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item href={'/'}>www.kemski-bonn.de</Breadcrumb.Item>
        {items}
      </Breadcrumb>
    </div>
  );
}

export default PageIndicator;
