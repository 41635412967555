import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';
import React from 'react';
import LoginView from './shared/LoginView/LoginView';
import GebaeudeinformationenView from './Gebaeudeinformation/GebaeudeinformationenView';

const Template = (): JSX.Element => {
  return (
    <>
      <Card.Header>
        <h4>Gebäudeinformationen</h4>
      </Card.Header>
      <Card.Body>
        {<LoginView>{fetchLoginState => <GebaeudeinformationenView onRefresh={fetchLoginState} />}</LoginView>}
      </Card.Body>
    </>
  );
};

const gebaeudeinformationen = {
  name: 'Gebäudeinformationen',
  defaultRoute: '/Radon_Stadt/Gebäudeinformationen',
  type: CardContent,
  template: Template,
};
export default gebaeudeinformationen;
