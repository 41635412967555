import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';

const template = (
  <>
    <Card.Header>
      <h4>Publikationen</h4>
    </Card.Header>
    <Card.Body>
      <ul>
        <li className={'mb-3 mt-4'}>
          Kemski, J. (1993): Radonmessungen in der Bodenluft zur Lokalisierung von Störungen im Neuwieder Becken
          (Mittelrhein).- Bonner geowiss. Schr., 8, 144 S.
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R. (2005): Schadstoff Radon - das im Bau versteckte Risiko.- Sicherheitsberater, 1/2:
          14-17
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R. (2006): Natürliche und anthropogene Einflussfaktoren auf die Radonkonzentration in
          Wohnräumen.- in: Ettenhuber, E., Giessing, R., Beier, E., Bayer, A. (Hrsg.): Strahlenschutz-Aspekte bei
          natürlicher Radioaktivität.- Fortschritte im Strahlenschutz, FS-06-141-T: 494-501, Verl. TÜV Rheinland
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Schneiders, H., Siehl, A., Wiegand, J. (1992): Geological structure and geochemistry
          controlling radon in soil gas.- Rad. Prot. Dosim., 45, 1/2: 235-239
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Schneiders, H., Siehl, A., Wiegand, J. (1994): Geogene Faktoren der
          Strahlenexposition unter besonderer Berücksichtigung des Radonpotentials (Abschlussbericht zum
          Forschungsvorhaben St. Sch. 1118).- Schriftenreihe Reaktorsicherheit und Strahlenschutz, BMU-1994-396, 102 S.
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A. (1994): Towards a classification of radon prone areas in Germany.- in:
          Barnet. I., Neznal, M. (Hrsg.): Radon investigations in Czech Republic V and the 2nd International Workshop on
          the Geological aspects of radon risk mapping.- 101-109
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A. (1995): Geogene Ursachen der Strahlenexposition durch Radon.- in: Dörhöfer,
          G., Thein, J., Wiggering, H. (Hrsg.): Umweltqualitätsziele - natürliche Variabilität - Grenzwerte.-
          Umweltgeol. heute, 5: 85-89
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A. (1996 a): Geogene Faktoren der Strahlenexposition unter besonderer
          Berücksichtigung des Radon-Potentials (Abschlussbericht zum Forschungsvorhaben St. Sch. 4062).- Schriftenreihe
          Reaktorsicherheit und Strahlenschutz, BMU-1996-470, 76 S.
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A. (1996 b): Die terrestrische Strahlung durch natürlich radioaktive Elemente
          in Gesteinen und Böden.- in: Siehl, A. (Hrsg.): Umweltradioaktivität.- 69-96, Ernst & Sohn
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A. (1996 c): Das geogene Radon-Potential.- in: Siehl, A. (Hrsg.):
          Umweltradioaktivität.- 179-222, Ernst & Sohn
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A. (1996 d): Classification and mapping of radon affected areas in Germany.-
          Environm. Int., 22, Suppl. 1: S789-S798
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A., Neznal, M., Matolin, M. (2012): Erarbeitung fachlicher Grundlagen zum
          Beurteilung der Vergleichbarkeit unterschiedlicher Messmethoden zur Bestimmung der
          Radonbodenluftkonzentration.- Abschlussbericht zum Vorhaben 3609S10003; 2 Bände
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A., Stegemann, R. (2005): Radon transfer from ground to houses and prediction
          of indoor radon in Germany based on geological information.- in: Radioactivity in the Environment, 7:
          McLaughlin, J.P.; Simopoulos, S.E.; Steinhäusler, F. (Hrsg.): The Natural Radiation Environment VII: 820-832
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A., Stegemann, R., Valdivia-Manchego, M. (2002): Transferfunktion für die
          Radonkonzentration in der Bodenluft und der Wohnraumluft (Abschlussbericht zu den Forschungsvorhaben St. Sch.
          4186 und St. Sch. 4187: Ermittlung einer Transferfunktion für die Radonkonzentration in der Bodenluft und in
          der Wohnraumluft incl. Radonmessungen in Häusern zur Validierung des geologisch induzierten Radonpotentials.
          Teil A: Bodenuntersuchungen zum geogenen Radonpotential. Teil B: Validierung der geologischen Prognose durch
          Messungen der Radonkonzentration in Gebäuden).- Schriftenreihe Reaktorsicherheit und Strahlenschutz,
          BMU-2002-598, 206 S.
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A., Valdivia-Manchego, M. (2006): Radon risk prediction in Germany based on
          gridded geological maps and soil gas measurements.- in: Barnet, I., Neznal, M., Pacherova, P. (Hrsg.): Radon
          investigations in Czech Republic XI and the 8th International Workshop on the Geological aspects of radon risk
          mapping.- 139-156
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Siehl, A., Valdivia-Manchego, M. (2009): From radon hazard to risk prediction - based
          on geological maps, soil gas and indoor measurements in Germany.- Environm. Geol., 56: 1269-1279
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Klingel, R., Stegemann (2004): Validierung der regionalen Verteilungen der Radonkonzentration in
          Häusern mittels Radonmessungen unter Berücksichtigung der Bauweise (Abschlussbericht zum Forschungsvorhaben
          St. Sch. 4271).- Schriftenreihe Reaktorsicherheit und Strahlenschutz, BMU-2004-641, 77 S.
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J.; Kunte, A. (2017): Ermittlung und Quantifizierung der durch die Umsetzung der EURATOM-Direktive von
          Pflichten betroffenen Arbeitsplätze.- Abschlussbericht zum BfS-Forschungsvorhaben 3616S12340
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Lehmann, R., Siehl, A., Stegemann, R. (2000): Radon mapping in Germany - state of investigation
          and future work.- in: Barnet, I., Neznal, N. (Hrsg.): Radon investigation in Czech Republic VIII and fifth
          International Workshop on the geological aspects of Radon Risk Mapping.- 45-52
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Siehl, A., Stegemann, R., Valdivia-Manchego, M. (1998): Mapping the geogenic radon potential in
          Germany using GIS-techniques.- in: Barnet, I., Neznal, M. (Hrsg.): Radon investigations in the Czech Repubic
          VII and the fourth international workshop on the Geological Aspects of Radon Risk Mapping.- 45-52
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Siehl, A., Valdivia-Manchego, M. (1998): Das geogene Radon-Potential in Deutschand.- in: Winter,
          M.; Henrichs, K.; Doerfel, H. (Hrsg.): Radioaktivität in Mensch und Umwelt.- Fortschritte im Strahlenschutz,
          FS-98-98-T: 397-403
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski. J., Siehl, A., Stegemann, R., Valdivia-Manchego, M. (1999): Geogene Faktoren der Strahlenexposition
          unter besonderer Berücksichtigung des Radon-Potentials (Abschlussbericht zum Forschungsvorhaben St. Sch.
          4106).- Schriftenreihe Reaktorsicherheit und Strahlenschutz, BMU-1999-534, 133 S.
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Siehl, A., Stegemann, R., Valdivia-Manchego, M. (2001): Mapping the geogenic radon potential in
          Germany.- Science Total Environm., 272: 217-230
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J., Siehl, A., Valdivia-Manchego, M. (1999): Mapping and prediction of geogenic radon potential in
          Germany.- Il Nuovo Cimento, 22 C, 3-4: 295-300
          <br />
        </li>
        <li className={'mb-3'}>
          Kemski, J.; Uhlig, R. (2019): Erarbeitung von DIN-Normen bzgl. baulicher Maßnahmen für den Radonschutz, Los 1
          „Radon“.- Abschlussbericht zum BfS-Forschungsvorhaben 3617S12250
          <br />
        </li>
        <li className={'mb-3'}>
          Klingel, R., Kemski, J. (2000): Influence of underground mining on the geogenic radon potential.- Proc.
          Workshop "Radon in the Living Environment, 19-23 April 1999, Athens, Greece": 773-785
          <br />
        </li>
      </ul>
    </Card.Body>
  </>
);

const publikationen = {
  name: 'Publikationen',
  defaultRoute: '/Unternehmen/Publikationen',
  type: CardContent,
  template,
};
export default publikationen;
