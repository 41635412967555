let jwt: string | null = null;

export const getJwt = (): string | null => jwt;

export const setJwt = (val: string): void => {
  jwt = val;
};

export const deleteJwt = (): void => {
  jwt = null;
};

export const getHeaderObject = (): { 'X-Authorization': string } => {
  if (jwt === null) {
    throw new Error('No jwt present');
  }
  return {
    'X-Authorization': `Bearer ${getJwt()}`,
  };
};
