import { Card } from 'react-bootstrap';
import CardContent from '../../../../../components/Content/components/CardContent';
import { Link } from 'react-router-dom';

const template = (
  <>
    <Card.Header>
      <h4>Anmeldung eingegangen</h4>
    </Card.Header>
    <Card.Body>
      <p>Bestätigung der Anmeldung</p>
      <p>
        <Link to={'/'}>Startseite</Link>
      </p>
    </Card.Body>
  </>
);

const anmeldungBestaetigung = {
  name: 'Anmeldung Bestätigung',
  defaultRoute: '/Radon_Stadt/Anmeldung_Bestätigung',
  type: CardContent,
  template,
};
export default anmeldungBestaetigung;
