import { boolean, InferType, object, ref, string } from 'yup';

export const salutationValues = ['Herr', 'Frau'];

export const loginSchema = object({
  salutation: string().required().oneOf(salutationValues).default(''),
  lastName: string().required().default(''),
  firstName: string().required().default(''),
  street: string().required().default(''),
  houseNumber: string().required().default(''),
  additionalInfo: string().default(''),
  zip: string()
    .default('')
    .required()
    .matches(/^[0-9]+$/)
    .min(5)
    .max(5),
  city: string().required().default(''),
  telephone: string().default(''),
  email: string().email().required().default(''),
  password: string()
    .required()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)
    .default(''),
  passwordConfirm: string()
    .oneOf([ref('password')])
    .default(''),
  termsAccepted: boolean().required().default(false).oneOf([true]),
});

export const loginSchemaNames = {
  salutation: 'Anrede',
  lastName: 'Nachname',
  firstName: 'Vorname',
  street: 'Straße',
  houseNumber: 'Hausnummer',
  additionalInfo: 'Adresszusatz',
  zip: 'PLZ',
  city: 'Ort',
  telephpne: 'Telefon',
  email: 'Mail',
  password: 'Passwort',
  passwordConfirm: 'Passwort bestätigen',
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoginType extends InferType<typeof loginSchema> {}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const initialValues: LoginType = loginSchema.default();
