import CardContent from '../../../components/Content/components/CardContent';
import { createApiCardTemplate } from '../../../components/Content/components/ApiCardTemplate';

const title = 'Aufstellungsanleitung';

const aufstellungsanleitungRadonstadt = {
  name: 'Aufstellungsanleitung',
  defaultRoute: '/Radon_Stadt/Aufstellungsanleitung',
  type: CardContent,
  template: createApiCardTemplate(title, 'aufstellungsanleitung', 'radon-stadt'),
};
export default aufstellungsanleitungRadonstadt;
