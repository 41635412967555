import { Container, Row } from 'react-bootstrap';

function Header(): JSX.Element {
  return (
    <header className="bg-primary">
      <Container>
        <Row>
          <div>
            <div>
              <a href={'/'} className={'text-decoration-none'}>
                <h3 className="display-3 text-white mt-3 mb-2">Dr. J. Kemski</h3>
                <p className="lead mb-4 text-white">
                  <b>
                    Sachverständigenbüro
                    <br />
                    ö.b.u.v. Sachverständiger für Radon (IHK Bonn/Rhein-Sieg)
                  </b>
                </p>
              </a>
            </div>
          </div>
        </Row>
      </Container>
    </header>
  );
}

export default Header;
