import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';

const template = (
  <>
    <Card.Header>
      <h4>Radon in Privathaushalten</h4>
    </Card.Header>
    <Card.Body>
      <p>
        Im Strahlenschutzgesetz (StrlSchG) ist für Aufenthaltsräumen ein <b>Referenzwert</b> von <b>300 Bq/m³</b> im
        Jahresmittel genannt, der nicht überschritten werden darf.
      </p>
      <p>
        Weltweite epidemiologische Studien über den Zusammenhang zwischen Radon in der Raumluft in Gebäuden und dem
        Risiko, an Lungenkrebs zu erkranken, haben ergeben, dass bereits ab einer Konzentration von 140 Bq/m3 im
        Jahresmittel ein schrittweiser Anstieg des Lungenkrebsrisikos zu beobachten ist. Daher empfiehlt u.a. die
        Weltgesundheitsorganisation WHO einen sogenannten reference level von 100 Bq/m3 im Jahresmittel, der nicht
        überschritten werden sollte. Denselben Wert benennt auch der Ausschuss für Innenraumschadstoffe (AIR) in
        Deutschland.
      </p>
      <p>
        Die Messung der Radonkonzentration in den eigenen vier Wänden ist daher stets eine sinnvolle Maßnahme. Sie
        schützen damit sich und ihre Familie. Zudem tragen Werte auf einem akzeptablen Niveau auch zur Wertsteigerung
        einer Immobilie bei.
      </p>
      <p>
        Erhöhte Radonkonzentrationen in der Raumluft können in allen Teilen Deutschlands auftreten. Regional
        unterschiedlich ist lediglich die Wahrscheinlichkeit bzw. Häufigkeit, mit der dies auftritt. In dieser Weise
        sind auch Deutschland-Karten der Radonbelastung zu interpretieren. In einigen Regionen finden sich mehr, in
        anderen Regionen weniger Häuser mit erhöhten Radonwerten.
      </p>
      <p>
        Die in den letzten Jahren verstärkt umgesetzten baulichen Maßnahmen zur Energieeinsparung können in diesem
        Zusammenhang zu einer Verschärfung des Problems führen. Einer Abdichtung der Gebäudehülle zur Vermeidung
        unnötiger Wärmeverluste steht als negativer Nebeneffekt eine ungewollte Anreicherung von Innenraumschadstoffen
        wie Radon u.a. gegenüber.
      </p>
      <br />
      <p>
        <b>Leistungsangebot:</b>
        <ul>
          <li>Langzeitmessungen der Radonkonzentration in der Raumluft nach DIN/ISO 11 665-4 mit Exposimetern</li>
          <li>
            kontinuierliche Messungen der Radonkonzentration in der Raumluft nach DIN/ISO 11 665-5 mit Hilfe "aktiver"
            (elektronischer) Messgeräte
          </li>
          <li>Hausbegehung mit Begutachtung und Lokalisierung von Radoneintrittspfaden ins Gebäude (radon sniffing)</li>
          <li>
            Bewertung der Ergebnisse und Empfehlungen zur weiteren Vorgehensweise (z.B.: Sanierungskonzept/-maßnahmen)
          </li>
          <li>ggf. fachliche Begleitung und Überwachung der Sanierungsmaßnahmen sowie Erfolgskontrolle</li>
        </ul>
      </p>
      <p>
        Einfach durchzuführende Lang- und Kurzzeitmessungen mit Radonexposimetern können Sie bei unserer Firma{' '}
        <a target={'_blank'} rel={'noreferrer'} href={'https://www.radon-analytics.com/'}>
          Radon Analytics
        </a>{' '}
        im Internet bestellen.
      </p>
    </Card.Body>
  </>
);

const privathaushalte = {
  name: 'Radon in Privathaushalten',
  defaultRoute: '/Leistungsübersicht/Radon_In_Privathaushalten',
  type: CardContent,
  template,
};
export default privathaushalte;
