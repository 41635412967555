import { Col, Form, Row } from 'react-bootstrap';
import * as React from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { capFirstLetter } from '../../../../util/string-util';

const formText =
  <T,>(
    handleChange: (e: React.ChangeEvent<unknown>) => void,
    values: T,
    touched: FormikTouched<T> | undefined,
    errors: FormikErrors<T> | undefined,
    enableErrors: boolean,
    prefix: string | undefined
  ) =>
  (field: string, placeholder: string, required = true, disabled = false, type: string | undefined = undefined) =>
    (
      <Form.Control
        type={type ?? 'text'}
        name={prefix !== undefined ? `${prefix}.${field}` : field}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={values[field]}
        onChange={handleChange}
        placeholder={placeholder}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isInvalid={!!touched?.[field] && !!errors?.[field]}
        isValid={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          enableErrors && !!values?.[field] && !!touched?.[field] && !errors?.[field] && (required || !!values?.[field])
        }
        disabled={disabled}
      />
    );

const formCheck =
  <T,>(
    handleChange: (e: React.ChangeEvent<unknown>) => void,
    values: T,
    touched: FormikTouched<T> | undefined,
    errors: FormikErrors<T> | undefined,
    enableErrors: boolean,
    prefix: string | undefined
  ) =>
  (field: string, label: string, disabled = false) =>
    (
      <Form.Check
        inline
        label={label}
        name={prefix !== undefined ? `${prefix}.${field}` : field}
        type={'checkbox'}
        id={`inline-checkbox-${prefix}-${field}`}
        onChange={handleChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        checked={values[field]}
        disabled={disabled}
      />
    );

const formSelect =
  <T,>(
    handleChange: (e: React.ChangeEvent<unknown>) => void,
    values: T,
    touched: FormikTouched<T> | undefined,
    errors: FormikErrors<T> | undefined,
    enableErrors: boolean,
    prefix: string | undefined
  ) =>
  (field: string, name: string, entries: string[], disabled = false) =>
    (
      <Form.Control
        as={'select'}
        name={prefix !== undefined ? `${prefix}.${field}` : field}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={values[field]}
        onChange={handleChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isInvalid={!!touched?.[field] && !!errors?.[field]}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isValid={enableErrors && !!values?.[field] && !!touched?.[field] && !errors?.[field]}
        disabled={disabled}
      >
        <option value={''}>{name}</option>
        {entries.map(e => (
          <option key={e} value={e}>
            {e}
          </option>
        ))}
      </Form.Control>
    );

const formGroupSelect =
  <T,>(
    handleChange: (e: React.ChangeEvent<unknown>) => void,
    values: T,
    touched: FormikTouched<T> | undefined,
    errors: FormikErrors<T> | undefined,
    enableErrors: boolean,
    prefix: string | undefined
  ) =>
  (field: string, name: string, label: string, entries: string[], disabled = false) =>
    (
      <Form.Group
        as={Col}
        md={'7'}
        controlId={`validationFormik${capFirstLetter(prefix ?? '')}${capFirstLetter(field)}`}
      >
        <Form.Label>{label}</Form.Label>
        {formSelect(handleChange, values, touched, errors, enableErrors, prefix)(field, name, entries, disabled)}
      </Form.Group>
    );

const selectRow =
  <T,>(
    handleChange: (e: React.ChangeEvent<unknown>) => void,
    values: T,
    touched: FormikTouched<T> | undefined,
    errors: FormikErrors<T> | undefined,
    enableErrors: boolean,
    prefix: string | undefined
  ) =>
  (field: string, name: string, label: string, entries: string[], disabled = false) =>
    (
      <Row className={'mb-3'}>
        {formGroupSelect(
          handleChange,
          values,
          touched,
          errors,
          enableErrors,
          prefix
        )(field, name, label, entries, disabled)}
      </Row>
    );

const factory = <T,>(
  handleChange: (e: React.ChangeEvent<unknown>) => void,
  values: T,
  touched: FormikTouched<T> | undefined,
  errors: FormikErrors<T> | undefined,
  enableErrors = true,
  prefix: string | undefined = undefined
): {
  formText: (field: string, placeholder: string, required?: boolean, disabled?: boolean, type?: string) => JSX.Element;
  formSelect: (field: string, name: string, entries: string[], disabled?: boolean | undefined) => JSX.Element;
  formCheck: (field: string, label: string, disabled?: boolean | undefined) => JSX.Element;
  formGroupSelect: (field: string, name: string, label: string, entries: string[], disabled?: boolean) => JSX.Element;
  selectRow: (field: string, name: string, label: string, entries: string[], disabled?: boolean) => JSX.Element;
} => {
  return {
    formText: formText(handleChange, values, touched, errors, enableErrors, prefix),
    formSelect: formSelect(handleChange, values, touched, errors, enableErrors, prefix),
    formCheck: formCheck(handleChange, values, touched, errors, enableErrors, prefix),
    formGroupSelect: formGroupSelect(handleChange, values, touched, errors, enableErrors, prefix),
    selectRow: selectRow(handleChange, values, touched, errors, enableErrors, prefix),
  };
};

export default factory;
