import CardContent from '../../../components/Content/components/CardContent';
import { createApiCardTemplate } from '../../../components/Content/components/ApiCardTemplate';

const title = 'Ablauf';

const ablaufRadonstadt = {
  name: 'Ablauf',
  defaultRoute: '/Radon_Stadt/Ablauf',
  type: CardContent,
  template: createApiCardTemplate(title, 'ablauf', 'radon-stadt'),
};
export default ablaufRadonstadt;
