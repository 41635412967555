import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Colors from '../../../../../util/colors';
import * as React from 'react';
import { setJwt } from '../../../../../util/jwtProvider';
import passwortVergessen from '../../Gebaeudeinformation/pages/PasswortVergessen';

type Props = {
  onRefresh: () => void;
};

const LoginForm = ({ onRefresh }: Props): JSX.Element => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null as boolean | null);

  const login = async (email: string, password: string) => {
    setDisabled(true);
    try {
      const response = await fetch('/api/session.php', {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: {
          'content-Type': 'application/json',
        },
      });
      if (response.status === 201) {
        const jwt = await response.json();
        setJwt(jwt[0]);
        setError(false);
        onRefresh();
      } else {
        setError(true);
        setDisabled(false);
      }
    } catch (e) {
      setError(true);
      setDisabled(false);
    }
  };

  return (
    <Form>
      <Form.Group className={'mb-3'} controlId={'loginEmail'}>
        <Form.Label>Mail-Adresse</Form.Label>
        <Form.Control
          type={'email'}
          placeholder={'Mail-Adresse'}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group className={'mb-4'} controlId={'loginPassword'}>
        <Form.Label>Passwort</Form.Label>
        <Form.Control
          type={'password'}
          placeholder={'Passwort'}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </Form.Group>
      {error && (
        <p>
          <span style={{ color: Colors.red }}>Login fehlgeschlagen!</span>
        </p>
      )}
      <Button variant={'primary'} onClick={() => login(email, password)} disabled={disabled}>
        Login
      </Button>{' '}
      <Button variant={'outline-primary'} onClick={() => history.push(passwortVergessen.defaultRoute)}>
        Passwort vergessen
      </Button>
    </Form>
  );
};

export default LoginForm;
