import React from 'react';
import Header from './components/Header/Header';
import Navigation from './components/Navigation/Navigation';
import PageIndicator from './components/PageIndicator/PageIndicator';
import Content from './components/Content/Content';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './components/GlobalStyle/GlobalStyle';
import ScrollToTop from './ScrollToTop';

function App(): JSX.Element {
  return (
    <GlobalStyle>
      <Header />
      <Router basename={'/'}>
        <ScrollToTop>
          <Navigation />
          <PageIndicator />
          <Content />
          <Footer />
        </ScrollToTop>
      </Router>
    </GlobalStyle>
  );
}

export default App;
