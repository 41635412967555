import React from 'react';
import { Card } from 'react-bootstrap';
import ApiContent from './ApiContent';

export type Props = {
  pageName: string;
  module: string | undefined;
  title: string;
};

const ApiCardTemplate = ({ pageName, title, module }: Props): JSX.Element => (
  <>
    <Card.Header>
      <h4>{title}</h4>
    </Card.Header>
    <Card.Body>
      <ApiContent pageName={pageName} module={module} />
    </Card.Body>
  </>
);

const createApiCardTemplate =
  (title: string, pageName: string, module: string | undefined = undefined) =>
  (): JSX.Element =>
    <ApiCardTemplate title={title} pageName={pageName} module={module} />;

export default ApiCardTemplate;
export { createApiCardTemplate };
