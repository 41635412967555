import formFactory from '../../shared/formFactory';
import * as React from 'react';
import {
  ExposimeterType,
  roomOptions,
  storyOptions,
  walkThroughTimeOptions,
  yesNoOptions,
  yesNoUnknownOptions,
} from '../types';
import { FormikErrors, FormikTouched } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { capFirstLetter } from '../../../../../util/string-util';

type Props = {
  handleChange: (e: React.ChangeEvent<unknown>) => void;
  values: ExposimeterType;
  touched: FormikTouched<ExposimeterType> | undefined;
  errors: FormikErrors<ExposimeterType> | undefined;
  prefix: string;
};

const ExposimeterForm = ({ handleChange, values, touched, errors, prefix }: Props): JSX.Element => {
  const { formText } = formFactory(handleChange, values, touched, errors, true, prefix);
  const { selectRow } = formFactory(handleChange, values, touched, errors, true, prefix);

  return (
    <>
      <Row>
        <Form.Group as={Col} md={'7'} controlId={`validationFormik${capFirstLetter(prefix)}ExposimeterNr`}>
          <Form.Label>Exposimeter-Nr.</Form.Label>
          {formText('exposimeterNr', 'Exposimeter-Nr.', true, true)}
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={'7'} controlId={`validationFormik${capFirstLetter(prefix)}MeasurementStart`}>
          <Form.Label>Messbeginn*</Form.Label>
          {formText('measurementStart', 'Messbeginn', true, false, 'date')}
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={'7'} controlId={`validationFormik${capFirstLetter(prefix)}MeasurementEnd`}>
          <Form.Label>Messende (erst nach Ende der Messung einzutragen)</Form.Label>
          {formText('measurementEnd', 'Messende', true, false, 'date')}
        </Form.Group>
      </Row>
      {selectRow('story', 'Etage', 'Etage*', storyOptions)}
      {selectRow('room', 'Messraum', 'Messraum*', roomOptions)}
      {selectRow('connectionToFloor', 'Erdberührung', 'Ist der Messraum erdberührt?*', yesNoOptions)}
      {selectRow(
        'connectionToEarth',
        'Durchdrinung ins Erdreich',
        'Existieren Durchdringungen aus dem Messraum ins umgebende Erdreich?',
        yesNoUnknownOptions
      )}
      {selectRow('walkThroughTime', 'Begehung', 'Begehung des Messraumes', walkThroughTimeOptions)}
    </>
  );
};

export default ExposimeterForm;
