import { Card } from 'react-bootstrap';
import CardContent from '../../components/Content/components/CardContent';

const template = (
  <>
    <Card.Header>
      <h4>Impressum</h4>
    </Card.Header>
    <Card.Body>
      <p>
        Dr. Joachim Kemski
        <br />
        Sachverständigenbüro
      </p>
      <h4>Kontakt</h4>
      <br />
      <p>
        <b>Dr. Joachim Kemski</b>
        <br />
        <br />
        Euskirchener Straße 54
        <br />
        D - 53121 Bonn
        <br />
        Telefon: +49 (0) 228 / 96292-41
        <br />
        Fax. +49 (0) 228 / 96292-49
        <br />
        Mail: <a href={'mailto:info@kemski-bonn.de'}>info@kemski-bonn.de</a>
        <br />
        Internet: <a href={'www.kemski-bonn.de'}>www.kemski-bonn.de</a>
        <br />
      </p>
      <p>
        <b>Sitz</b>
        <br />
        Bonn
      </p>
      <p>
        <b>Umsatzsteuer-Identifikationsnummer</b>
        <br />
        DE 283 072 720
      </p>
      <p>
        <b>Haftungshinweis</b>
        <br />
        Die Inhalte externer Links werden von uns nicht regelmäßig überprüft. Für den Inhalt der verlinkten Seiten sind
        ausschließlich deren Betreiber verantwortlich.
      </p>
      <p>
        <b>Inhaltlich verantwortlich</b>
        <br />
        Dr. Joachim Kemski
        <br />
        Euskirchener Straße 54
        <br />
        D - 53121 Bonn
        <br />
      </p>
      <p>
        <b>Bei Fragen und Anregungen bezüglich des Internetauftritts:</b>
        <br />
        <a href={'mailto:info@kemski-bonn.de'}>info@kemski-bonn.de</a>
      </p>
    </Card.Body>
  </>
);

const impressum = {
  name: 'Impressum',
  defaultRoute: '/Impressum',
  type: CardContent,
  template,
};
export default impressum;
