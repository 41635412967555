import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';

const template = (
  <>
    <Card.Header>
      <h4>Kooperationen</h4>
    </Card.Header>
    <Card.Body>
      <p>
        <a target={'_blank'} rel={'noreferrer'} href={'https://radonova.com/'}>
          Radonova AB
        </a>
      </p>
      <p>
        Die Firma Radonova mit Sitz in Uppsala/Schweden ist ein großer, international tätiger und renommierter Anbieter
        von Radonexposimetern. Die Kooperation beinhaltet die Zusammenarbeit auf dem Gebiet der Radonraumluftmessungen.
      </p>
      <p>Somit können auch unsere Kunden die jeweils aktuellsten Produkte dieser Firma nutzen.</p>
      <p>
        Radonova besitzt eine Akkreditierung der schwedischen Akkreditierungsbehörde SWEDAC (Swedish Board for
        Accreditation and Conformity Assessment), die der Akkreditierung des Deutschen Kalibrierdienstes gleichgestellt
        ist. Radonova nimmt regelmäßig und erfolgreich an internationalen Ringvergleichen und Kalibrierungen für
        Radonexposimeter teil – so auch beim Bundesamt für Strahlenschutz (BfS) in Deutschland. Entsprechende Nachweise
        dokumentieren diese Teilnahme.{' '}
      </p>
    </Card.Body>
  </>
);

const kooperationen = {
  name: 'Kooperationen',
  defaultRoute: '/Unternehmen/Kooperationen',
  type: CardContent,
  template,
};
export default kooperationen;
