import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';
import { Formik, FormikValues } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as React from 'react';
import { initialValues, loginSchema } from './Anmeldung/types';
import formFactory from './shared/formFactory';
import Colors from '../../../util/colors';
import anmeldungBestaetigung from './Anmeldung/pages/AnmeldungBestaetigung';
import datenverarbeitungRadonstadt from './Datenverarbeitung';

const UNEXPECTED_ERROR = 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.';
const ALREADY_TAKEN = 'Die angegebene E-Mail Adresse wird bereits verwendet.';

const Template = (): JSX.Element => {
  const [error, setError] = useState(null as string | null);
  const [enabled, setEnabled] = useState(true);

  const history = useHistory();

  const submit = async (values: FormikValues) => {
    if (!enabled) {
      return;
    }
    if (!values.termsAccepted) {
      return;
    }

    setEnabled(false);
    const valuesCopy = { ...values };
    delete valuesCopy.passwordConfirm;
    if (!values.hasProductionSite2) {
      delete valuesCopy.productionSite2;
    }

    try {
      const response = await fetch('/api/register.php', {
        method: 'POST',
        body: JSON.stringify(valuesCopy),
        headers: {
          'content-Type': 'application/json',
        },
      });
      if (response.status === 409) {
        setError(ALREADY_TAKEN);
      } else if (response.status !== 201) {
        setError(UNEXPECTED_ERROR);
      } else {
        history.push(anmeldungBestaetigung.defaultRoute);
      }
    } catch (e) {
      setError(UNEXPECTED_ERROR);
    }
    setEnabled(true);
  };

  return (
    <>
      <Card.Header>
        <h4>Anmeldung zu BfS-Forschungsvorhaben "Radon in der Stadt"</h4>
      </Card.Header>
      <Card.Body>
        <Formik validationSchema={loginSchema} initialValues={initialValues} onSubmit={submit}>
          {({ handleSubmit, handleChange, values, touched, errors, setValues, validateForm }) => {
            const { formText } = formFactory(handleChange, values, touched, errors);

            return (
              <Form noValidate onSubmit={handleSubmit}>
                <h6>*: Pflichtangabe</h6>
                <br />
                <h4>Persönliche Daten</h4>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} md={'6'} controlId={'validationFormikSalutation'}>
                    <Form.Label>Anrede*</Form.Label>
                    <div key={`inline-radio`}>
                      <Form.Check
                        inline
                        label="Frau"
                        name="salutation"
                        type={'radio'}
                        id={`inline-radio-2`}
                        value={'Frau'}
                        onChange={handleChange}
                        checked={values.salutation === 'Frau'}
                        isInvalid={!!touched.salutation && !!errors.salutation}
                      />
                      <Form.Check
                        inline
                        label="Herr"
                        name="salutation"
                        type={'radio'}
                        id={`inline-radio-1`}
                        value={'Herr'}
                        onChange={handleChange}
                        checked={values.salutation === 'Herr'}
                        isInvalid={!!touched.salutation && !!errors.salutation}
                      />
                    </div>
                  </Form.Group>
                </Row>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} md={'6'} controlId={'validationFormikFirstName'}>
                    <Form.Label>Vorname*</Form.Label>
                    {formText('firstName', 'Vorname')}
                  </Form.Group>
                  <Form.Group as={Col} md={'6'} controlId={'validationFormikLastName'}>
                    <Form.Label>Nachname*</Form.Label>
                    {formText('lastName', 'Nachname')}
                  </Form.Group>
                </Row>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} md={'12'} controlId={'validationFormikEmail'}>
                    <Form.Label>Mail*</Form.Label>
                    <Row>
                      <Col md={'6'}>
                        <InputGroup hasValidation>
                          <InputGroup.Text id={'inputGroupPrepend'}>@</InputGroup.Text>
                          <Form.Control
                            type={'email'}
                            placeholder={'Mail'}
                            aria-describedby={'inputGroupPrepend'}
                            name={'email'}
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!touched.email && !!errors.email}
                            isValid={!!touched.email && !errors.email}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} md={'6'} controlId={'validationFormikTelephone'}>
                    <Form.Label>
                      Telefon <small>(für eventuelle Rückfragen)</small>
                    </Form.Label>
                    <InputGroup hasValidation>{formText('telephone', 'Telefon', false)}</InputGroup>
                  </Form.Group>
                </Row>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} md={'6'} controlId={'validationFormikPassword'}>
                    <Form.Label>
                      Passwort* <small>(min. 8 Zeichen, muss Klein-/Großschreibung und eine Zahl enthalten)</small>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type={'password'}
                        placeholder={'Passwort'}
                        name={'password'}
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!touched.password && !!errors.password}
                        isValid={!!touched.password && !errors.password}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className={'mb-5'}>
                  <Form.Group as={Col} md={'6'} controlId={'validationFormikPasswordConfirm'}>
                    <Form.Label>Passwort wiederholen*</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type={'password'}
                        placeholder={'Passwort wiederholen'}
                        name={'passwordConfirm'}
                        value={values.passwordConfirm}
                        onChange={handleChange}
                        isInvalid={!!touched.passwordConfirm && !!errors.passwordConfirm}
                        isValid={
                          !!touched.passwordConfirm &&
                          !errors.passwordConfirm &&
                          (values.passwordConfirm?.length ?? 0) > 0
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
                <h4>Adresse</h4>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} md={'4'} controlId={'validationFormikStreet'}>
                    <Form.Label>Straße*</Form.Label>
                    {formText('street', 'Straße')}
                  </Form.Group>
                  <Form.Group as={Col} md={'2'} controlId={'validationFormikHouseNumber'}>
                    <Form.Label>Hausnummer*</Form.Label>
                    {formText('houseNumber', 'Hausnr.')}
                  </Form.Group>
                </Row>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} md={'6'} controlId={'validationFormikAdditionalInfo'}>
                    <Form.Label>Adresszusatz</Form.Label>
                    {formText('additionalInfo', 'Adresszusatz')}
                  </Form.Group>
                </Row>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} md={'2'} controlId={'validationFormikZip'}>
                    <Form.Label>PLZ*</Form.Label>
                    {formText('zip', 'PLZ')}
                  </Form.Group>
                  <Form.Group as={Col} md={'4'} controlId={'validationFormikCity'}>
                    <Form.Label>Stadt*</Form.Label>
                    {formText('city', 'Stadt')}
                  </Form.Group>
                </Row>
                <Row className={'mb-3'}>
                  <Form.Group as={Col} md={'12'}>
                    <Form.Check
                      inline
                      label={
                        <>
                          <span
                            style={{
                              color: errors.termsAccepted ? Colors.red : undefined,
                            }}
                          >
                            Ich habe diese Hinweise gelesen und stimme der
                          </span>{' '}
                          <a href={datenverarbeitungRadonstadt.defaultRoute} target={'_blank'} rel={'noreferrer'}>
                            Weiterverarbeitung
                          </a>{' '}
                          <span
                            style={{
                              color: errors.termsAccepted ? Colors.red : undefined,
                            }}
                          >
                            meiner Kontaktdaten als Ansprechperson zu.
                          </span>
                        </>
                      }
                      name="termsAccepted"
                      type={'checkbox'}
                      id={`inline-checkbox-terms`}
                      onChange={handleChange}
                      checked={values.termsAccepted}
                    />
                  </Form.Group>
                </Row>
                {Object.entries(touched).length > 0 && Object.entries(errors).length > 0 && (
                  <Row className={'mb-3'}>
                    <Col>
                      <span style={{ color: Colors.red }}> Bitte überprüfen Sie die Angaben!</span>
                    </Col>
                  </Row>
                )}
                {error && (
                  <h5 style={{ color: Colors.red }} className={'mb-4'}>
                    {error}
                  </h5>
                )}
                <Button type={'submit'}>Anmelden</Button>
              </Form>
            );
          }}
        </Formik>
      </Card.Body>
    </>
  );
};

const anmeldung = {
  name: 'Anmeldung',
  defaultRoute: '/Radon_Stadt/Anmeldung',
  type: CardContent,
  template: Template,
};

export default anmeldung;
