import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';

const template = (
  <>
    <Card.Header>
      <h4>Radon in der Bodenluft</h4>
    </Card.Header>
    <Card.Body>
      <p>
        Radonmessungen in der Bodenluft dienen zur Bestimmung der Radonquellstärke im geogenen Untergrund. Ziel der
        Untersuchungen ist es, im Vorfeld von Baumaßnahmen Empfehlungen zum radongeschützten Bauen zu geben. Hintergrund
        ist die Vorgabe im Strahlenschutzgesetz (StrlSchG), wonach Neubauten mit Aufenthaltsräumen oder Arbeitsplätzen
        so zu errichten sind, dass der Zutritt von Radon aus dem Baugrund verhindert oder zumindest erschwert wird (§123
        StrlSchG).
      </p>
      <p>
        Bei der Ausweisung von Baugebieten bzw. der Erschließung von Wohn- und Gewerbegrundstücken oder vor dem Bau
        privat oder öffentlich genutzter Gebäude sollten daher im Vorfeld der Baumaßnahme entsprechende Untersuchungen
        durchgeführt werden. Deren Umfang richtet sind vorrangig nach Geologie, Größe des Untersuchungsgebietes und
        späterer geplanter Nutzung und Bauausführung der Gebäude.
      </p>
      <p>
        Es sei darauf hingewiesen, dass auch bereits bei nur durchschnittlichen Radonkonzentrationen in der Bodenluft
        bei unzureichender Bauplanung und/oder –ausführung anschließend eine Überschreitung des gesetzlich vorgegebenen
        Referenzwertes in der Raumluft beobachtet werden kann.
      </p>
      <p>
        Kommunale Verwaltungen (Gemeinde, Kreis/Landkreis) können mit Hilfe solcher Feldmessungen eine flächenhafte
        Kartierung des geogenen Radonpotenzials in ihrem Verantwortungsbereich durchführen. Diese Informationen können
        als Planungsgrundlage für Detailuntersuchungen oder für anschließend gezielt eingesetzte Raumluftmessungen in
        höher belasteten Gebieten genutzt werden.
      </p>
      <p>
        Einen Sonderfall stellt die Lokalisierung und Kartierung von Wegsamkeiten im Untergrund mit Hilfe von
        Radonmessungen dar, ggf. ergänzt um andere Gase wie beispielsweise CO2.
      </p>
      <br />
      <p>
        <b>Leistungsangebot:</b>
        <ul>
          <li>
            Festlegung des Untersuchungsprogrammes unter Berücksichtigung von Geologie, Größe und späterer Nutzung
          </li>
          <li>
            Durchführung der Untersuchungen vor Ort: Probenahme der Bodenluft nach DIN ISO 11 665-10, Messung der
            Radonkonzentration in der Bodenluft, Bestimmung des Gasdurchlässigkeit des Bodens und Berechnung des
            geogenen Radonpotenzials (Vorgehensweise entsprechend Empfehlung des Bundesamts für Strahlenschutz (BfS)
          </li>
          <li>Kartierung von Wegsamkeiten im Untergrund</li>
          <li>Gutachten mit Bewertung der Ergebnisse und ggf. Empfehlungen zum radongeschützten Bauen</li>
        </ul>
      </p>
    </Card.Body>
  </>
);

const radonInDerBodenluft = {
  name: 'Radon in der Bodenluft',
  defaultRoute: '/Leistungsübersicht/Radon_in_der_Bodenluft',
  type: CardContent,
  template,
};
export default radonInDerBodenluft;
