import { Page, Route } from './types';

const narrowRoute = (route: Route): { route: string; page: Page } => {
  if ('route' in route) {
    return route;
  } else {
    return {
      route: route.defaultRoute,
      page: route,
    };
  }
};

export { narrowRoute };
