import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CardContent from '../../components/Content/components/CardContent';
import leistungsuebersicht from './Leistungsuebersicht/Leistungsuebersicht';

const template = (
  <>
    <Card.Header>
      <h3>Willkommen bei der Internetpräsenz des Sachverständigenbüros Dr. Kemski</h3>
    </Card.Header>
    <Card.Body>
      <p className={'text-danger'}>
        <b>
          Anerkannte Stelle für Messung der Radon-222-Aktivitätskonzentration an Arbeitsplätzen gemäß § 155 Abs. 4
          Strahlenschutzverordnung (StrlSchV)
        </b>
      </p>
      <p>
        Seit Ende 2018 ist das neue Strahlenschutzgesetz in Kraft. Dadurch ergeben sich neue Aufgaben in Bezug auf die
        Ermittlung der Radonbelastung - beispielsweise für Kommunen, Firmen und Arbeitgeber. Dies gilt insbesondere in{' '}
        <b>Radonvorsorgegebieten</b>, in denen eine <b>Messpflicht an Arbeitsplätzen</b> besteht. Solche Messungen sind
        aber auch anderenorts sinnvoll, z.B. unter dem Gesichtspunkt des Gesundheitsschutzes der Beschäftigten.
      </p>
      <p>
        Wollen Sie wissen, ob dieses Thema auch für Sie als Arbeitgeber, Sicherheitsbeauftragter o.ä. relevant ist und
        was Sie ggf. tun müssen oder tun sollten, sind wir der richtige Ansprechpartner. Wünschen Sie eine{' '}
        <b>unverbindliche Beratung</b> zur möglichen <b>Vorgehensweise bei Messungen</b>, zum Aufwand und zu{' '}
        <b>Preisen</b> für Radonmessungen, dann nehmen Sie bitte telefonisch (0228 9629241) oder per Mail (
        <a href={'mailto:kemski@kemski-bonn.de'}>kemski@kemski-bonn.de</a>) Kontakt zu uns auf.
      </p>
      <p>
        Details zu unseren <b>Dienstleistungen</b> entnehmen Sie bitte der{' '}
        <b>
          <Link to={leistungsuebersicht.defaultRoute}>Leistungsübersicht</Link>
        </b>
        .
      </p>
      <p>
        <b>Unser Profil:</b>
      </p>
      <ul>
        <li>Wir sind seit mehr als 25 Jahren erfolgreich im Umwelt- und im Strahlenschutz tätig.</li>
        <li>Wir arbeiten fachkompetent, interdisziplinär und wirtschaftlich.</li>
        <li>Wir orientieren uns an der geltenden Rechtsgrundlage und dem Stand der Wissenschaft.</li>
        <li>Wir beraten unsere Kunden bedarfs- und termingerecht.</li>
        <li>Unsere Dienstleistungen erfolgen praxisbezogen und kosten-nutzen-optimiert.</li>
        <li>
          Sie reichen von der wissenschaftlichen Beratung über die objektbezogene Planung inkl. Projektmanagement bis
          hin zu umfassenden Messungen.
        </li>
        <li>
          Unsere Arbeitsgrundlage ist präzises Expertenwissen, basierend auf langjähriger Erfahrung und ständiger
          Weiterbildung.
        </li>
      </ul>
      <p>
        <b>Wir bieten auch für komplexe Probleme fundierte Lösungsansätze - fragen Sie nach!</b>
      </p>
    </Card.Body>
  </>
);

const sidebar = [
  <>
    <Card.Header>
      <h5>Kontakt</h5>
    </Card.Header>
    <Card.Body>
      <p>
        <b>Dr. Joachim Kemski</b>
        <br />
        Sachverständigenbüro
      </p>
      <p>
        Euskirchener Straße 54
        <br />
        D - 53121 Bonn
        <br />
        <br />
        Telefon: +49 (0) 228 / 96292-41
        <br />
        Fax. +49 (0) 228 / 96292-49
        <br />
        Mail: <a href={'mailto:info@kemski-bonn.de'}>info@kemski-bonn.de</a>
      </p>
    </Card.Body>
  </>,
  <>
    <Card.Header>
      <h5>Radon Analytics</h5>
    </Card.Header>
    <Card.Body>
      <p>
        Hier können Sie einfach durchzuführende Lang- und Kurzzeitmessungen mit Radonexposimetern über unsere Firma{' '}
        <a target={'_blank'} rel={'noreferrer'} href={'https://www.radon-analytics.com/'}>
          {' '}
          Radon Analytics
        </a>{' '}
        im Internet bestellen.
      </p>
    </Card.Body>
  </>,
];

const home = {
  name: 'Home',
  defaultRoute: '/',
  type: CardContent,
  template,
  sidebar,
};
export default home;
