import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';

const template = (
  <>
    <Card.Header>
      <h4>Radon in öffentlichen Gebäuden</h4>
    </Card.Header>
    <Card.Body>
      <p>
        Radon ist ein Innenraumschadstoff, der sich in allen Gebäuden finden lässt. Über lange Zeiträume in höheren
        Konzentrationen eingeatmet, kann sich für Bewohner und Nutzer das Lungenkrebsrisiko erhöhen.
      </p>
      <p>
        Auch in öffentlichen Gebäuden (z.B.: Verwaltungen, Schulen, Kindergärten, Krankenhäuser) können erhöhte
        Radonkonzentrationen auftreten. Bei Vorhandensein geeigneter Eintrittspfade (z.B.: Risse im Fundament, undichte
        Rohrdurchführungen) kann radonhaltige Bodenluft aus dem Untergrund ins Gebäude eindringen, sich in der Raumluft
        anreichern und damit zu einer unerwünschten Radonbelastung der Nutzer führen.
      </p>
      <p>
        Dies betrifft sowohl Beschäftigte (z.B.: Personal) als auch Dritte (z.B.: Besucher, Schüler), die sich dauernd
        oder zeitweise in Büros, Werkstätten, Verkaufsräumen, Klassenzimmern oder anderen Räumen aufhalten.
      </p>
      <p>
        Besonderes Augenmerk muss auf Gebäude mit „sensibler“ Nutzung wie beispielsweise Schulen und Kindergärten gelegt
        werden, weil hier - neben dem Lehr- bzw. Betreuungspersonal - Kinder betroffen sein können, bei denen von einer
        höheren Strahlenempfindlichkeit und damit einem höheren Gesundheitsrisiko ausgegangen werden muss.
      </p>
      <p>
        Unterschiedliche Nutzungsbedingungen und Aufenthaltszeiten der Betroffenen machen in der Regel auf das jeweilige
        Objekt zugeschnittene Untersuchungskonzepte notwendig.
      </p>
      <br />
      <p>
        <b>Leistungsangebot:</b>
        <ul>
          <li>
            Erarbeitung einer Messstrategie mit Festlegung des Untersuchungsprogrammes (Orientierung an DIN ISO 11
            665-8: Anzahl von Räumen, Messverfahren, zusätzliche Datenerhebung)
          </li>
          <li>Durchführung der Messungen vor Ort nach DIN ISO 11 665 (Teil 4 und/oder Teil 5)</li>
          <li>Einweisung und ggf. Schulung von Personal und Betroffenen</li>
          <li>
            Gutachten mit Bewertung der Ergebnisse und Empfehlungen zur weiteren Vorgehensweise (z.B.:
            Sanierungskonzept/-maßnahmen)
          </li>
          <li>ggf. fachliche Begleitung und Überwachung der Sanierungsmaßnahmen sowie Erfolgskontrolle</li>
        </ul>
      </p>
    </Card.Body>
  </>
);

const radonInOeffentlichenGebaeuden = {
  name: 'Radon in öffentlichen Gebäuden',
  defaultRoute: '/Leistungsübersicht/Radon_in_öffentlichen_Gebäuden',
  type: CardContent,
  template,
};
export default radonInOeffentlichenGebaeuden;
