import CardContent from '../../../components/Content/components/CardContent';
import { createApiCardTemplate } from '../../../components/Content/components/ApiCardTemplate';

const title = 'Datenverarbeitung';

const datenverarbeitungRadonstadt = {
  name: 'Datenverarbeitung',
  defaultRoute: '/Radon_Stadt/Datenverarbeitung',
  type: CardContent,
  template: createApiCardTemplate(title, 'datenverarbeitung', 'radon-stadt'),
};
export default datenverarbeitungRadonstadt;
