import CardContent from '../../../components/Content/components/CardContent';
import { createApiCardTemplate } from '../../../components/Content/components/ApiCardTemplate';

const title = 'Beschreibung Forschungsvorhaben des Bundesamts für Strahlenschutz (BfS)';

const beschreibung = {
  name: 'Beschreibung',
  defaultRoute: '/BfS_Arbeitsplatz',
  type: CardContent,
  template: createApiCardTemplate(title, 'bfs-arbeitsplatz-beschreibung'),
};
export default beschreibung;
