import CardContent from '../../../components/Content/components/CardContent';
import { Card } from 'react-bootstrap';
import referenzenUndAuftraggeber from './ReferenzenUndAuftraggeber';
import { Link } from 'react-router-dom';

const template = (
  <>
    <Card.Header>
      <h4>Ansprechpartner und Qualifikationen</h4>
    </Card.Header>
    <Card.Body>
      <h5>
        <b>Dr. Joachim Kemski</b>
      </h5>
      <p>
        <br />
        Telefon: +49 228 96292- 41
        <br />
        Mail: <a href={'mailto:kemski@kemski-bonn.de'}>kemski@kemski-bonn.de</a>
      </p>

      <ul>
        <li>
          <b>Ausbildung</b>
          <br />
          Studium der Geologie an der Ludwig-Maximilians-Universität München und der
          Rheinischen-Friedrich-Wilhelms-Universität Bonn Promotion zum Dr. rer. nat. bei Prof. Dr. A. Siehl
        </li>
        <li>
          <b>Werdegang</b>
          <br />
          Wissenschaftlicher Mitarbeiter am Geologischen Institut der Universität Bonn: Mitarbeit (Planung, Organisation
          und Durchführung) in BMU-geförderten FuE-Vorhaben „Die Einwirkung des geologischen Untergrundes auf die
          natürliche Strahlenexposition in der BRD“ und „Geogene Faktoren der Strahlenexposition unter besonderer
          Berücksichtigung des Radonpotentials"
        </li>
        <li>seit 1995 selbständig in eigenem Sachverständigenbüro tätig</li>
      </ul>

      <br />
      <h5>Der Firmeninhaber ist seit</h5>
      <br />
      <ul>
        <li>
          Juni 2005 erster öffentlich bestellter und vereidigter Sachverständige (IHK Bonn/Rhein-Sieg) zum Thema Radon
          in Deutschland
        </li>
        <li>1993 Mitglied im Deutsch-Schweizerischen Fachverband für Strahlenschutz e.V.</li>
        <li>
          2016 Mitglied im Gemeinschaftsarbeitsausschuss NABau/NHRS „Radongeschütztes Bauen“ des DIN und Mitarbeit an
          Erstellung der DIN/TS 18117-1
        </li>
        <li>13.3.2021 vom BfS anerkannte Stelle für Radonmessung an Arbeitsplätzen (§ 155 Abs. 4 StrlSchV)</li>
      </ul>
      <br />

      <h5>
        <b>Qualifikationen und Arbeitsschwerpunkte</b>
      </h5>
      <br />
      <h5>
        Planung, Organisation und Durchführung umfangreicher <b>Radonmesskampagnen für Bund und Länder</b>, z.B.:
      </h5>
      <br />
      <ul>
        <li>
          Erarbeitung der Grundlagen zur Durchführung von Radonmessungen in der Bodenluft für die entsprechende
          Deutschland-Karte (ca. 4 000 Messorte)
        </li>
        <li>
          umfangreiche Bodenluftmesskampagnen für den Bund (Bundesamt für Strahlenschutz, BfS) und diverse Bundesländer
          (Brandenburg, Nordrhein-Westfalen, Schleswig-Holstein) zur Ermittlung der Radonkonzentration in der Bodenluft
          im Zuge der Ausweisung von Radon(vorsorge)gebieten nach § 121 StrlSchG (jeweils zwischen ca. 60 und 700
          Messorte)
        </li>
        <li>
          Durchführung von Raumluftmesskampagnen für den Bund und verschiedene Landesbehörden (z.B.: BfS, Bayern,
          Hessen, Rheinland-Pfalz) mit jeweils einigen 100 Haushalten
        </li>
      </ul>
      <br />

      <h5>
        Bearbeitung zahlreicher <b>Forschungsvorhaben</b> des Bundesamts für Strahlenschutz (BfS), z.B.:
      </h5>
      <br />
      <ul>
        <li>
          Erstellung der <b>Datenbank BuRG</b> (Bundeseinheitliche Datei „Radon in Gebäuden“) des BfS
        </li>
        <li>
          <b>Radonmessungen in der Bodenluft</b>: Vorhaben „Erarbeitung fachlicher Grundlagen zum Beurteilung der
          Vergleichbarkeit unterschiedlicher Messmethoden zur Bestimmung der Radonbodenluftkonzentration“
          <br />
          <a
            href={'https://doris.bfs.de/jspui/bitstream/urn:nbn:de:0221-201203237824/3/BfS_2012_3609S10003_Bd1.pdf'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            Bericht (PDF)
          </a>
        </li>
        <li>
          <b>Radonmessungen in Wohngebäuden</b>: Vorhaben „Validierung der regionalen Verteilungen der
          Radonkonzentration in Häusern mittels Radonmessungen unter Berücksichtigung der Bauweise“
        </li>
        <li>
          <b>Radon am Arbeitsplatz</b>: Vorhaben „Ermittlung und Quantifizierung der durch die Umsetzung der
          EURATOM-Direktive von Pflichten betroffenen Arbeitsplätze“
          <br />
          <a
            href={'https://doris.bfs.de/jspui/bitstream/urn:nbn:de:0221-2019061218406/3/BfS_2019_3616S12340.pdf'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            Bericht (PDF)
          </a>
        </li>
        <li>
          <b>Radonschutz beim Bauen</b>: Vorhaben: „Erarbeitung von DIN-Normen bzgl. baulicher Maßnahmen für den
          Radonschutz“
          <br />
          <a
            href={'https://doris.bfs.de/jspui/bitstream/urn:nbn:de:0221-2021050526831/3/BfS_2021_3617S12250.pdf'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            Bericht (PDF)
          </a>
        </li>
        <li>
          <b>Radonmessungen in Privathaushalten</b>: Vorhaben „Ermittlung der aktuellen Verteilung der
          Radonkonzentration in deutschen Wohnungen“ mit Messungen in Deutschland-weit 6 000 Haushalten“
        </li>
        <li>
          <b>Radonmessungen an Arbeitsplätzen</b>: Vorhaben „Durchführung einer bundesweiten Erhebung zur
          Radonkonzentration an Arbeitsplätzen“ (laufend)
        </li>
        <li>
          <b>Radonkonzentration in der Bodenluft</b>: Vorhaben „Untersuchung der Abhängigkeit der Radonkonzentration in
          der Bodenluft in rund 1 Meter Tiefe von Wetterparametern für Böden mit verschiedenen Permeabilitäten und
          Nutzungshistorien“ (laufend)
        </li>
      </ul>
      <br />
      <p>
        <b>Bodenluft- und Raumluftmessungen für Kommunen und Unternehmen der freien Wirtschaft</b>, z.B. Messungen an
        Arbeitsplätzen in Radon(vorsorge)gebieten bzw. Arbeitsfeldern nach Anlage 8 StrlSchG (Wasserwerke u.a.) oder im
        Vorfeld von Neubaumaßnahmen (präventiver baulicher Radonschutz)
      </p>
      <br />
      <p>
        Erarbeitung von <b>Stellungnahmen</b> u.ä. zu Fragen der Umweltradioaktivität und Radonthematik für Verbände,
        Vereine u.ä., z.B.: Geothermie, Immobilienwirtschaft, Wohnungsgesellschaften
      </p>
      <br />
      <p>
        Eine Liste unserer Auftraggeber finden Sie unter{' '}
        <Link to={referenzenUndAuftraggeber.defaultRoute}>Referenzen</Link>.
      </p>
    </Card.Body>
  </>
);

const ansprechpartnerUndQualifikationen = {
  name: 'Ansprechpartner und Qualifikationen',
  defaultRoute: '/Unternehmen/Ansprechpartner_und_Qualifikationen',
  type: CardContent,
  template,
};
export default ansprechpartnerUndQualifikationen;
