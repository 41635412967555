type Props = {
  children: JSX.Element[];
};

const GlobalStyle = (props: Props): JSX.Element => {
  const style = {
    fontSize: '18px',
  };

  return <div style={style}>{props.children}</div>;
};

export default GlobalStyle;
