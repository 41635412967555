import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';

const template = (
  <>
    <Card.Header>
      <h4>Referenzen und Aufraggeber</h4>
    </Card.Header>
    <Card.Body>
      <p>
        <b>Unsere Auftraggeber sind Behörden, Unternehmen der freien Wirtschaft sowie Privathaushalte.</b>
      </p>
      <p>
        Folgende Themenstellungen haben wir im Zuge zahlreicher Projekte in den letzten Jahren u.a. bearbeitet:
        <ul>
          <li>
            Messung und Bewertung der Radonaktivitätskonzentration in der Bodenluft mit Empfehlungen zum
            radongeschützten Bauen in zahlreichen Kommunen und für Bauträger
          </li>
          <li>
            Messung der Radonaktivitätskonzentration in der Bodenluft für regionale Radonkartierungen in mehreren
            Bundesländern
          </li>
          <li>
            Messung und Bewertung der Radonaktivitätskonzentration in Wohngebäuden in zahlreichen Landkreisen und
            Kommunen verschiedener Bundesländer
          </li>
          <li>
            verschiedene Forschungsvorhaben des Bundesamtes für Strahlenschutz (BfS) zu Boden- und Raumluftmessungen
          </li>
          <li>
            Ermittlung und Bewertung der Radonbelastung in öffentlichen Gebäuden (z.B.: Schulen, Kindergärten) in
            verschiedenen Kommunen und fachliche Begleitung bei baulichen Sanierungsmaßnahmen zur Reduzierung erhöhter
            Radonaktivitätskonzentrationen
          </li>
          <li>
            Messung und Bewertung der Radonkonzentrationen an Arbeitsplätzen entsprechend den gesetzlichen Vorgaben nach
            StrlSchG (u.a. Radonvorsorgegebiete, Arbeitsfelder nach Anlage 8) sowie Dosisabschätzung der Beschäftigten
          </li>
          <li>
            Begutachtung des Einflusses umgehenden Bergbaus auf die Radonbelastung in der Bodenluft und in Gebäuden
          </li>
          <li>Messung von Radon und anderen Bodengasen zur Lokalisierung von Wegsamkeiten im Untergrund</li>
          <li>
            Gammaspektrometrische Untersuchungen von Feststoffen und Bewertung der daraus resultierenden
            Strahlenbelastung
          </li>
        </ul>
      </p>
      <br />
      <p>Wir würden uns freuen, auch Sie künftig im Kreis unserer zufriedenen Kunden begrüßen zu dürfen.</p>
      <br />
      <p>Nachfolgend eine Auswahl unserer Auftraggeber:</p>
      <p>
        <b>Bundes- und Landesbehörden u.ä.</b>
        <ul>
          <li>Bayerisches Staatsministerium für Umwelt, Gesundheit und Verbraucherschutz</li>
          <li>Bundesamt für Strahlenschutz</li>
          <li>Geologischer Dienst Nordrhein-Westfalen</li>
          <li>Hessisches Landesamt für Umwelt und Geologie</li>
          <li>Landesbetrieb Bau und Immobilien Hessen (LBIH)</li>
          <li>Landesamt für Arbeitsschutz, Verbraucherschutz und Gesundheit Brandenburg</li>
          <li>Landesoberbergamt für das Saarland und Rheinland Pfalz</li>
          <li>
            Ministerium für Energiewende, Landwirtschaft, Umwelt, Natur und Digitalisierung des Landes
            Schleswig-Holstein
          </li>
          <li>Ministerium für Klimaschutz, Umwelt, Energie und Mobilität Rheinland-Pfalz</li>
          <li>Sächsische Staatliche Betriebsgesellschaft für Umwelt und Landwirtschaft (SBUL)</li>
        </ul>
      </p>
      <p>
        <b>Städte, Kreise u.ä.</b>
        <ul>
          <li>Rheinisch-Bergischer Kreis</li>
          <li>Stadt Bad Nauheim</li>
          <li>Stadt Essen</li>
          <li>Stadt Hagen, Gebäudewirtschaft</li>
          <li>Stadt Iserlohn</li>
          <li>Stadt Leverkusen</li>
          <li>Stadt Neuss</li>
          <li>Stadt Oberhausen</li>
          <li>Stadt Wuppertal, Gebäudemanagement</li>
          <li>Verbandsgemeinde Rhein-Mosel</li>
          <li>Verbandsgemeinde Winnweiler</li>
          <li>Verbandsgemeindeverwaltung Lauterecken</li>
          <li>Verbandsgemeindeverwaltung Rockenhausen</li>
          <li>Verbandsgemeindeverwaltung Sprendlingen-Gensingen</li>
        </ul>
      </p>
      <p>
        <b>Verbände, Institute u.ä.</b>
        <ul>
          <li>Bundesverband der Ziegelindustrie</li>
          <li>Deutsches Institut für Bautechnik (DIBt)</li>
          <li>Institut für Ziegelforschung Essen e.V.</li>
          <li>Max-Planck-Gesellschaft zur Förderung der Wissenschaften e.V.</li>
          <li>Schiefer-Fachverband in Deutschland e.V.</li>
          <li>Verband kommunaler Immobilien- und Gebäudewirtschaftsunternehmen e.V. (VKIG)</li>
        </ul>
      </p>
      <p>
        <b>Wohnungsbaugenossenschaften, Projektentwickler u.ä.</b>
        <ul>
          <li>Arnsberger Wohnungsbaugenossenschaft eG</li>
          <li>Bad Nauheimer Wohnungsbau GmbH</li>
          <li>BPD Immobilienentwicklung GmbH</li>
          <li>CDM Smith Consult GmbH</li>
          <li>Grüning Consulting GmbH</li>
          <li>HPC AG</li>
          <li>ICG Ingenieure GmbH</li>
          <li>Ixocon Logistikzentrum 11 GmbH & Co. KG</li>
          <li>NORD PROJECT GBI Beteiligungsgesellschaft mbH</li>
          <li>Ramboll Deutschland GmbH</li>
          <li>Ratisbona Projektentwicklung KG</li>
          <li>SEG Rhein-Nahe</li>
          <li>UMR GmbH part of the Anthesis Consulting Group</li>
          <li>Wohnbau GmbH</li>
          <li>Wohnungsbaugenossenschaft Hochsauerland e.G.</li>
          <li>weisenburger projekt</li>
        </ul>
      </p>
      <p>
        <b>Radonvorsorgegebiete</b>
        <ul>
          <li>ARYZTA Bakeries Deutschland GmbH</li>
          <li>Gemeinde Seiffen</li>
          <li>Goslarer Gebäude Management</li>
          <li>Lebenshilfewerk Erzgebirge</li>
          <li>Stadt Oberharz am Brocken</li>
          <li>Stadtbus Goslar GmbH</li>
          <li>Team 96 – Finanzmanagement/Liegenschaftsverwaltung Augsburg</li>
          <li>Technische Universität Clausthal</li>
          <li>Weltkulturerbe Rammelsberg</li>
        </ul>
      </p>
      <p>
        <b>Bergbau u.ä.</b>
        <ul>
          <li>DMT GmbH & Co. KG</li>
          <li>DSK Deutsche Steinkohle AG / Ruhrkohle AG</li>
          <li>Oberbergamt Saarbrücken</li>
        </ul>
      </p>
      <p>
        <b>Wasserversorgungsunternehmen</b>
        <ul>
          <li>Energieversorgung Mittelrhein</li>
          <li>Energie- und Wasserversorgung Rheine GmbH</li>
          <li>Gemeinde Stemwede</li>
          <li>Kreiswasserwerk Bitburg-Prüm</li>
          <li>Mark-E AG</li>
          <li>Oberhessische Versorgungsbetriebe</li>
          <li>RheinEnergie AG</li>
          <li>Rheinhessische Energie- und Wasserversorgungs-GmbH</li>
          <li>Stadt Bornheim</li>
          <li>Stadt Rahden</li>
          <li>Stadtwerke Ahaus GmbH</li>
          <li>Stadtwerke Bielefeld</li>
          <li>Stadtwerke Erkrath</li>
          <li>Stadtwerke Espelkamp AöR</li>
          <li>Stadtwerke Gescher GmbH</li>
          <li>Stadtwerke Hamm GmbH</li>
          <li>Stadtwerke Lemgo</li>
          <li>Stadtwerke Marburg</li>
          <li>Stadt Petershagen</li>
          <li>Stadtwerke Ratingen GmbH</li>
          <li>Stadtwerke Steinheim GmbH</li>
          <li>Stadtwerke Troisdorf GmbH</li>
          <li>SVS-Versorgungsbetriebe GmbH</li>
          <li>Verbandswasserwerk Euskirchen</li>
          <li>Vereinigte Wasserwerke Mittelrhein GmbH</li>
          <li>Wasserverband Hochsauerland</li>
          <li>Wasserwerk Titz</li>
          <li>Zweckverband Eifel-Mosel</li>
        </ul>
      </p>
      <p>
        <b>Freie Wirtschaft u.ä.</b>
        <ul>
          <li>Arcadis Germany GmbH</li>
          <li>Benninghoven GmbH & Co. KG</li>
          <li>Boxheimer + Scheuermann</li>
          <li>Dr. Hug Geoconsult GmbH</li>
          <li>fm geotechnik</li>
          <li>Gesellschaft für Anlagen- und Reaktorsicherheit mbH</li>
          <li>Golder Associates GmbH</li>
          <li>GSM living GmbH</li>
          <li>Hydrodata GmbH</li>
          <li>IBL Umwelt- und Biotechnik GmbH</li>
          <li>Ingenieurgesellschaft Dr. Siekmann + Partner</li>
          <li>Institut für Geotechnik Zirfas</li>
          <li>Karst Ingenieure GmbH</li>
          <li>Kühn Geoconsulting GmbH</li>
          <li>NobelClad Europe GmbH</li>
          <li>rheinarbeit gGmbH</li>
          <li>SVB Sachverständigenbüro Dr. Sedat</li>
          <li>TPA GmbH</li>
          <li>Unternehmensgruppe Dr. Pfirrmann</li>
          <li>Wintershall Holding GmbH</li>
        </ul>
      </p>
    </Card.Body>
  </>
);

const referenzenUndAuftraggeber = {
  name: 'Referenzen und Auftraggeber',
  defaultRoute: '/Unternehmen/Referenzen_und_Auftraggeber',
  type: CardContent,
  template,
};
export default referenzenUndAuftraggeber;
