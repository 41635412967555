import CardContent from '../../../components/Content/components/CardContent';
import { Card } from 'react-bootstrap';

const template = (
  <>
    <Card.Header>
      <h4>Unsere Philosophie</h4>
    </Card.Header>
    <Card.Body>
      <p>
        Wir bieten umfassende Beratung in den Bereichen <b>Umweltgeologie</b> mit dem Schwerpunkt&nbsp;
        <b>Umweltradioaktivität</b>. Hierbei integrieren wir das <i>wissenschaftliche und technische Know-how</i> im
        Bereich der geowissenschaftlichen Forschung und des Strahlenschutzes in die Dienstleistung und helfen Ihnen bei
        der Lösung Ihrer Fragen durch unabhängige Expertengutachten.
      </p>
      <p>
        Die <b>Zufriedenheit unserer Kunden hat für uns oberste Priorität</b>. In einem ausführlichen Gespräch erfolgt
        gemeinsam mit dem Kunden zunächst eine Abklärung, welche Zielsetzung die Untersuchungen haben sollen.
      </p>
      <p>
        Nach der Erarbeitung eines Konzeptes und einer Messstrategie werden die eigentlichen Untersuchungen
        durchgeführt. Dabei wird - wenn möglich - ein schrittweises Vorgehen mit zwischengeschalteten Erfolgskontrollen
        gewählt, um ggf. auf neue Sachverhalte angemessen reagieren zu können.
      </p>
      <p>
        Abschließend erfolgt die Bewertung der Untersuchungen nach dem gängigen Regelwerk und es werden dem Kunden
        Empfehlungen zur Lösung des Problems an die Hand gegeben.
      </p>
      <p>
        Aufgrund unserer <b>langjährigen wissenschaftlichen Tätigkeit</b> und unserer Kooperationen mit verschiedenen
        Partnern sind wir in der Lage, auch Lösungsvorschläge und Konzepte abseits der routinemäßigen Fragestellungen zu
        erarbeiten.
      </p>
    </Card.Body>
  </>
);

const unserePhilosophie = {
  name: 'Unsere Philosophie',
  defaultRoute: '/Unternehmen/Unsere_Philosophie',
  type: CardContent,
  template,
};
export default unserePhilosophie;
