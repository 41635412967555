import { ContentProps } from '../types';
import { Card, Col, Container, Row } from 'react-bootstrap';

function CardContent(props: ContentProps): JSX.Element {
  const PropTemplate = props.template;
  return (
    <Container fluid={'md'}>
      <Row className={'mb-3'}>
        <Col>
          <Card className={'shadow-lg'}>{typeof PropTemplate === 'function' ? <PropTemplate /> : PropTemplate}</Card>
        </Col>
        {props.sidebar && (
          <Col lg={4}>
            {props.sidebar.map((e, i) => (
              <Card key={i} className={'shadow-lg mb-3'}>
                {e}
              </Card>
            ))}
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default CardContent;
