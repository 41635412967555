import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';

const template = (
  <>
    <Card.Header>
      <h4>Radon am Arbeitsplatz</h4>
    </Card.Header>
    <Card.Body>
      <p>
        Im Strahlenschutzgesetz (StrlSchG) sind in Anlage 8 Arbeitsplätze definiert, für die der Gesetzgeber verbindlich
        Radonmessungen zum Schutz der dort Beschäftigten vorgeschrieben hat. Hierzu zählen die Bereiche des untertägigen
        Bergbaus und der Schauhöhlen, der Radonbäder sowie der Wasserversorgungsunternehmen.
      </p>
      <p>
        Aber auch an allen anderen Arbeitsplätzen in Innenräumen sollte dieses Thema unter dem Gesichtspunkt des
        Gesundheitsschutzes der Beschäftigten nicht vernachlässigt werden.
      </p>
      <p>
        In Abhängigkeit von der Branche und der Art des Arbeitsplatzes (z.B.: Büro, Fertigung, Produktion, Schule) sind
        unterschiedliche Herangehensweisen zu wählen. So spielen die Nutzungsbedingungen in den Räumen und die
        Aufenthaltszeiten der Beschäftigten eine wichtige Rolle für die Planung von Messungen. In der Regel werden
        Ortsmessungen preiswert und einfach mit Hilfe sogenannter Exposimeter durchgeführt. Bei speziellen
        Fragestellungen sind kontinuierliche (zeitauflösende) Messungen sinnvoll bzw. notwendig, um den zeitlichen Gang
        der Radonkonzentration zu bestimmen. Diese Daten erlauben die Bewertung möglicher Zusammenhänge mit Raumnutzung
        (z.B.: Lüftung) oder Arbeitsabläufen (z.B.: Maschinenlaufzeiten, Ventilation).
      </p>
      <br />
      <p>
        <b>Leistungsangebot:</b>
        <ul>
          <li>
            Festlegung des Untersuchungsprogrammes (Umfang, z.B. Anzahl betroffener Arbeitsplätze; Messmethodik, z.B.
            integrierende oder zeitauflösende Messung) unter Berücksichtigung der aktuellen Rechtslage (StrlSchG,
            StrlSchV) und entsprechender Normen
          </li>
          <li>
            Begutachtung der Vor-Ort-Situation und Durchführung der Arbeitsplatzmessungen (Exposimetermessungen,
            kontinuierliche Radonmessungen) nach DIN ISO 11 665
          </li>
          <li>Einweisung und ggf. Schulung des Personals vor Ort</li>
          <li>Expositionsabschätzung</li>
          <li>
            Gutachten mit Bewertung der Ergebnisse und Empfehlungen zur weiteren Vorgehensweise (z.B.:
            Sanierungskonzept/-maßnahmen)
          </li>
          <li>ggf. fachliche Begleitung und Überwachung der Sanierungsmaßnahmen sowie Erfolgskontrolle</li>
        </ul>
      </p>
    </Card.Body>
  </>
);

const radonAmArbeitsplatz = {
  name: 'Radon am Arbeitsplatz',
  defaultRoute: '/Leistungsübersicht/Radon_am_Arbeitsplatz',
  type: CardContent,
  template,
};
export default radonAmArbeitsplatz;
