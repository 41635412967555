import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';
import { Link } from 'react-router-dom';
import radonInDerBodenluft from './RadonInDerBodenluft';
import radonInOeffentlichenGebaeuden from './RadonInOeffentlichenGebaeuden';
import radonAmArbeitsplatz from './RadonAmArbeitsplatz';
import radionuklideInDerUmwelt from './RadionuklideInDerUmwelt';
import privathaushalte from './Privathaushalte';

const template = (
  <>
    <Card.Header>
      <h4>Leistungsübersicht</h4>
    </Card.Header>
    <Card.Body>
      <p>
        Unser Leistungsspektrum konzentriert sich auf Dienstleistungen in den Bereichen{' '}
        <b>Umweltradioaktivität und Strahlenschutz</b> mit dem Schwerpunkt <b>Radon</b>.
      </p>
      <p>
        Nachfolgend erhalten Sie einen Eindruck über unser jeweiliges Leistungsangebot. Wir empfehlen, dass Sie uns bei
        Interesse kontaktieren, damit wir Ihre spezifischen Wünsche besprechen und abklären können.
      </p>
      <p>
        Zu folgenden Fragestellungen können wir Ihnen unsere Hilfe anbieten:
        <ul>
          <li>
            <Link to={radonInDerBodenluft.defaultRoute}>{radonInDerBodenluft.name}</Link>
          </li>
          <li>
            <Link to={radonInOeffentlichenGebaeuden.defaultRoute}>{radonInOeffentlichenGebaeuden.name}</Link>
          </li>
          <li>
            <Link to={radonAmArbeitsplatz.defaultRoute}>{radonAmArbeitsplatz.name}</Link>
          </li>
          <li>
            <Link to={radionuklideInDerUmwelt.defaultRoute}>{radionuklideInDerUmwelt.name}</Link>
          </li>
          <li>
            <Link to={privathaushalte.defaultRoute}>{privathaushalte.name}</Link>
          </li>
        </ul>
      </p>
    </Card.Body>
  </>
);

const leistungsuebersicht = {
  name: 'Leistungsübersicht',
  defaultRoute: '/Leistungsübersicht/Leistungsübersicht',
  type: CardContent,
  template,
};
export default leistungsuebersicht;
