import { Card } from 'react-bootstrap';
import CardContent from '../components/Content/components/CardContent';
import { Link } from 'react-router-dom';

const template = (
  <>
    <Card.Header>
      <h4>Error</h4>
    </Card.Header>
    <Card.Body>
      <p>404 - Page not found</p>
      <p>
        Die Seite konnte leider nicht gefunden werden.
        <br />
        Bitte versuchen Sie es hier: <Link to={'/'}>Startseite</Link>
      </p>
    </Card.Body>
  </>
);

const error = {
  name: 'Error',
  defaultRoute: '/',
  type: CardContent,
  template,
  default: true,
};
export default error;
