import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import LoginForm from './LoginForm';
import * as React from 'react';
import { getHeaderObject, getJwt } from '../../../../../util/jwtProvider';

type Props = {
  children: (props: () => void) => JSX.Element;
};

const LoginView = ({ children }: Props): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState(null as boolean | null);

  const fetchLoginState = () => {
    if (getJwt() === null) {
      setLoggedIn(false);
      return;
    }
    fetch('/api/session.php', {
      headers: getHeaderObject(),
    })
      .then(response => {
        setLoggedIn(response.status === 200);
      })
      .catch(() => {
        setLoggedIn(false);
      });
  };

  useEffect(fetchLoginState, [children]);

  return loggedIn === null ? (
    <Spinner animation={'border'} />
  ) : loggedIn ? (
    children(fetchLoginState)
  ) : (
    <LoginForm onRefresh={fetchLoginState} />
  );
};

export default LoginView;
