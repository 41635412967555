import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';

const template = (
  <>
    <Card.Header>
      <h4>Radionuklide in der Umwelt</h4>
    </Card.Header>
    <Card.Body>
      <p>
        Ein Bestandteil der natürlichen Umgebungsstrahlung, die der Mensch immer und überall ausgesetzt ist, stellt die
        terrestrische Komponente dar. Deren wichtigste Quelle sind die natürlich vorkommenden radioaktiven Elemente
        (Kalium-40, Uran-Radium-Reihe, Thorium-Reihe). Diese sind in allen Böden und Gesteinen in unterschiedlichen
        Konzentrationen vorhanden. Als mineralische Komponente in Baumaterialien gelangen sie auch in das unmittelbare
        Umfeld der Bewohner und Nutzer von Gebäuden. Durch menschliche Aktivitäten werden künstliche Radionuklide wie
        beispielsweise Cs-137 großflächig in die Ökosysteme eingetragen und können dadurch zusätzlich zur
        Strahlenbelastung beitragen.
      </p>
      <p>
        In der Praxis kann sich damit die Frage nach einer möglichen Strahlenbelastung aus Baumaterialien stellen.
        Gammaspektrometrische Labormessungen von geeignetem Probenmaterial liefern die spezifischen
        Radionuklidaktivitäten von Kalium, Uran und Thorium. Diese Werte können als Basis für die Bewertung der von
        einem Baumaterial ausgehenden Strahlenbelastung im Gebäude genutzt werden.
      </p>
      <p>
        Mit Hilfe tragbarer Gammaspektrometer können die Radionuklidaktivitäten im Gelände direkt bestimmt und
        großflächig der Untergrund und möglicherweise vorhandene lokale Anomalien kartiert werden. Nicht selten werden
        derartige Informationen zur radiologischen Situation im Zuge von Neu- und/oder Umbaumaßnahmen benötigt, um ggf.
        belastetes Material im Vorfeld ausbauen und ersetzen zu können.
      </p>
      <br />
      <p>
        <b>Leistungsangebot:</b>
        <ul>
          <li>
            Erfassung und Bewertung natürlicher Radionuklide (K-40, Ra-226, Th-232) in Baustoffen, Böden und Gesteinen;
            bei notwendigen Laboruntersuchungen Zusammenarbeit mit einem hierauf spezialisierten akkreditierten Labor
          </li>
          <li>
            Feldmessung der Radionuklidaktivitäten natürlicher Radionuklide (K-40, Ra-226, Th-232) und der Dosisleistung
          </li>
        </ul>
      </p>
    </Card.Body>
  </>
);

const radionuklideInDerUmwelt = {
  name: 'Radionuklide in der Umwelt',
  defaultRoute: '/Leistungsübersicht/Radionuklide_in_der_Umwelt',
  type: CardContent,
  template,
};
export default radionuklideInDerUmwelt;
