import CardContent from '../../../components/Content/components/CardContent';
import { createApiCardTemplate } from '../../../components/Content/components/ApiCardTemplate';

const title = 'Beschreibung';

const beschreibungRadonstadt = {
  name: 'Beschreibung',
  defaultRoute: '/Radon_Stadt/Beschreibung',
  type: CardContent,
  template: createApiCardTemplate(title, 'beschreibung', 'radon-stadt'),
};
export default beschreibungRadonstadt;
