import { Route as RouteComponent, Switch } from 'react-router-dom';
import routes from '../../content/routes';
import { Route } from '../../content/types';
import { narrowRoute } from '../../content/util';

function Content(): JSX.Element {
  const createRoute = (routeElem: Route, i: number) => {
    const route = narrowRoute(routeElem);

    return (
      <RouteComponent key={i} exact={!route.page.default} path={route.route}>
        <route.page.type template={route.page.template} sidebar={route.page.sidebar} />
      </RouteComponent>
    );
  };

  const routeElements = routes.map(createRoute);
  return <Switch>{routeElements}</Switch>;
}

export default Content;
