import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import impressum from '../../content/pages/Impressum';
import datenschutz from '../../content/pages/Datenschutz';

function Footer(): JSX.Element {
  return (
    <footer className="py-5 bg-dark">
      <Container>
        <p className="m-0 text-center text-white">
          <Link className={'text-white'} to={datenschutz.defaultRoute}>
            Datenschutz
          </Link>
          &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
          <Link className={'text-white'} to={impressum.defaultRoute}>
            Impressum
          </Link>
        </p>
      </Container>
    </footer>
  );
}

export default Footer;
